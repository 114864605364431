import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { LostAndFound } from 'js/components/404/LostAndFound.js';

OnReady(() => {
  MonitoringInit();

  const laf = new LostAndFound(window.location.href, document.referrer, yxtLostAndFoundConfig);
  laf.installBasicHooks();
  laf.run();
});

